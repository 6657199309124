
import { defineComponent, ref, onBeforeMount } from "vue";
import * as EmailServices from "../api/helpers/Email";
import * as QuestionnaireServices from "../api/helpers/Questionnaire";
import * as LeadServices from "../api/helpers/Lead";
import * as ApplicationServices from "../api/helpers/Application";
import * as CatalogService from "../api/helpers/Catalog";
import TypeText from "../components/TypeText.vue";
import TypePhone from "../components/TypePhone.vue";
import TypeSwitch from "../components/TypeSwicth.vue";
import TypeEmail from "../components/TypeEmail.vue";
import TypeRadioButton from "../components/TypeRadioButton.vue";
import TypeYesNoButton from "../components/TypeYesNoButton.vue";
import TypeDropDown from "../components/TypeDropDown.vue";
import TypeDate from "../components/TypeDate.vue";
import TypeCheck from "../components/TypeCheck.vue";
import TypeMask from "../components/TypeMask.vue";
import Spinner from "../components/Spinner.vue";
import Questionnaire from "../models/Questionnaire";
import { useGtm } from "vue-gtm";
import i18n from "../locales/i18n";
import _ from "underscore";
import { getCurrentInstance } from "vue";
import "particles.js";
import * as FacebookServices from "../api/helpers/Facebook";
interface Location {
  assign(url: string | URL): void;
}
window.particlesJS = window.particlesJS || {};
export default defineComponent({
  name: "Register",
  props: {
    language: String,
  },
  components: {
    TypeText,
    TypePhone,
    TypeEmail,
    TypeSwitch,
    TypeRadioButton,
    TypeYesNoButton,
    TypeDropDown,
    TypeDate,
    TypeMask,
    TypeCheck,
    Spinner,
  },
  data() {
    return {
      currentStep: 0,
      submitted: false,
      isvalid: true,
      leadId: sessionStorage.getItem("leadId"),
      applicationId: sessionStorage.getItem("applicationId"),
      phoneLength: process.env.VUE_APP_PHONE_LENGTH,
      birthdate: new Date(),
      maxSteps: 0,
      minStep: 0,
      answers: {},
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_TEXT_COLOR,
    };
  },
  setup(props) {
    const app = getCurrentInstance();
    const toastTime = app?.appContext.config.globalProperties.$toastTime;
    var loading = ref(true);
    var questionnaire = ref({});
    var lastStep = ref(false);
    var goWA = ref(false);
    const questionnaires = ref({ Questionnaire });
    const getQuestionnaire = async (language: string) => {
      questionnaire.value = await QuestionnaireServices.find(language!).data;
    };
    return {
      questionnaire,
      questionnaires,
      getQuestionnaire,
      loading,
      toastTime,
      lastStep,
      goWA,
    };
  },
  watch: {
    language(value) {
      this.getQuestionnaire(value);
    },
  },
  mounted() {
    FacebookServices.pageView();
    this.initParticles();
    document.title = process.env.VUE_APP_TITLE + " | Cuestionario";
    window.scrollTo(0, 0);
    let { min, max } = this.getSteps();
    this.minStep = min;
    this.maxSteps = max;
    this.currentStep = Number(sessionStorage.getItem("step")) || this.minStep;
    if (
      this.currentStep > this.minStep &&
      (!this.leadId || !this.applicationId)
    )
      this.goFirst();
    this.to(this.currentStep);
    this.loading = false;
    const to = this.to;
    const router = this.$router;
    window.onpopstate = function() {
      const urlParams = new URLSearchParams(window.location.search);
      const step = urlParams.get("step");
      if (!step && Number(sessionStorage.getItem("step")) > max)
        router.push("../cuestionario");
      else {
        const next = parseInt(step || "0") || undefined;
        to(next);
      }
    };
  },
  methods: {
    getSteps() {
      this.getQuestionnaire(i18n.global.locale!);
      this.questionnaires.Questionnaire.prototype = QuestionnaireServices.find(
        i18n.global.locale!
      ).data;
      const max = Math.max(
        ..._.pluck(this.questionnaires.Questionnaire.prototype.steps, "step")
      );
      const min = Math.min(
        ..._.pluck(this.questionnaires.Questionnaire.prototype.steps, "step")
      );
      return { min, max };
    },
    register() {
      this.submitted = true;
      this.isvalid = true;
      for (var x of this.questionnaires.Questionnaire.prototype.steps) {
        if (this.currentStep == x.step) {
          this.isvalid =
            !_.some(x.answers, (y) => y.valid == false) && this.isvalid;
          for (var y of x.answers) {
            if (!y.valid) {
              this.isvalid = false;
              break;
            }
            Object.assign(this.answers, { [y.key]: y.value });
          }
        }
      }
      if (this.isvalid) {
        this.loading = true;
        this.submitted = false;
        this.updateApplication();
      }
    },
    WA() {
      this.submitted = true;
      this.isvalid = true;
      for (var x of this.questionnaires.Questionnaire.prototype.steps) {
        if (this.currentStep == x.step) {
          this.isvalid =
            !_.some(x.answers, (y) => y.valid == false) && this.isvalid;
          for (var y of x.answers) {
            if (!y.valid) {
              this.isvalid = false;
              break;
            }
            Object.assign(this.answers, { [y.key]: y.value });
          }
        }
      }
      if (this.isvalid) {
        this.loading = true;
        this.submitted = false;
        this.goWA = true;
        this.updateApplication();
      }
    },
    setValue(obj: any) {
      for (var x of this.questionnaires.Questionnaire.prototype.steps) {
        if (this.currentStep == x.step) {
          for (var y of x.answers) {
            if (y.key == obj.answerKey) {
              Object.assign(y, { value: obj.value });
              Object.assign(y, { valid: obj.valid });
              break;
            }
          }
        }
      }
    },
    createLead() {
      const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
      LeadServices.create(this.answers).then(
        (res) => {
          const {
            data: { lead, application, newEmail },
          } = res.data;
          sessionStorage.setItem("newEmail", newEmail ? "1" : "0");
          sessionStorage.setItem("leadId", lead.id);
          sessionStorage.setItem("applicationId", application.id);
          sessionStorage.setItem(
            "steps",
            JSON.stringify(Object.assign(steps, this.answers))
          );
          this.leadId = lead.id;
          this.applicationId = application.id;
          this.to(this.currentStep + 1);
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: err,
            life: this.toastTime,
          });
        }
      );
    },
    updateLead() {
      if (this.leadId) {
        const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
        LeadServices.update(this.answers, this.leadId).then(
          (res) => {
            const newEmail = res.data.data.newEmail;
            if (_.has(this.answers, "email"))
              sessionStorage.setItem("newEmail", newEmail ? "1" : "0");

            sessionStorage.setItem(
              "steps",
              JSON.stringify(Object.assign(steps, this.answers))
            );

            this.to(this.currentStep + 1);
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: err,
              life: this.toastTime,
            });
          }
        );
      }
    },
    updateApplication() {
      if (this.applicationId) {
        const steps = JSON.parse(sessionStorage.getItem("steps") || "{}");
        ApplicationServices.update(this.answers, this.applicationId).then(
          () => {
            sessionStorage.setItem(
              "steps",
              JSON.stringify(Object.assign(steps, this.answers))
            );
            this.to(this.currentStep + 1);
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: "error",
              summary: err,
              life: this.toastTime,
            });
          }
        );
      }
    },
    triggerEvent() {
      const gtm = useGtm();
      gtm?.trackEvent({
        event: "FireGTM",
      });
    },
    async to(step?: number) {
      if (step == this.maxSteps) this.lastStep = true;
      else this.lastStep = false;
      if (!step || step == 1) {
        sessionStorage.setItem("step", (this.currentStep - 1).toString());
        this.$router.push({ path: "registro", query: { step: 1 } });
      } else if (step - 1 > this.maxSteps) {
        return;
      } else {
        this.currentStep = step;
        sessionStorage.setItem("step", this.currentStep.toString());
        if (this.currentStep > this.maxSteps) {
          this.loading = true;
          sessionStorage.setItem("capiLeadId", "true");
          let answers = JSON.parse(sessionStorage.getItem("steps") || "{}");

          await CatalogService._find(
            `/MaxPrice?system=${process.env.VUE_APP_SYSTEM_ID}&order=id:asc`
          ).then((res) => {
            answers.budget = _.filter(res.data.data, (b) => {
              return b.id == answers.maxPriceId;
            });
            answers.budget = answers.budget[0].name;
          });
          await CatalogService._find(
            `/vehiclePurpose?system=${process.env.VUE_APP_SYSTEM_ID}&order=id:asc`
          ).then((res) => {
            answers.purpose = _.filter(res.data.data, (b) => {
              return answers.vehiclePurposes.includes(b.systems[0].id);
            });
            answers.purpose = _.map(answers.purpose, (p) => {
              return p.name;
            });
          });
          await CatalogService._find(
            `/vehicleSize?system=${process.env.VUE_APP_SYSTEM_ID}&order=id:asc`
          ).then((res) => {
            answers.seats = _.filter(res.data.data, (s) => {
              return answers.vehicleSizeId == s.id;
            });
            answers.seats = _.map(answers.seats, (s) => {
              return s.name;
            });
          });
          if(answers.vehiclePaymentMethod == 'A crédito')
            answers.vehiclePaymentMethod = 'Credit'
          else if(answers.vehiclePaymentMethod == 'De contado')
            answers.vehiclePaymentMethod = 'Cash'
          else if(answers.vehiclePaymentMethod == 'Aún no lo decido')
            answers.vehiclePaymentMethod = 'I don’t know yet'
          answers.payment = answers.vehiclePaymentMethod;
          if (answers.vehicleStatus == "1") answers.status = "Used";
          if (answers.vehicleStatus == "2") answers.status = "New";
          if (answers.vehicleStatus.toString() == "3")
            answers.status = "I don’t know yet";
          if (answers.payment.toString() == "2")
            answers.payment = "I don’t know yet";

          if (answers.purchasePeriod == "1") answers.period = "This month";
          else if (answers.purchasePeriod == "2") answers.period = "Next month";
          else if (answers.purchasePeriod == "3") answers.period = "In 3 months";
          else if (answers.purchasePeriod == "4") answers.period = "In more than 3 months";
          answers = _.pick(
            answers,
            "email",
            "name",
            "phone",
            "postalCode",
            "purpose",
            "status",
            "seats",
            "budget",
            "payment",
            "period"
          );
          let params = {
            template: "auto-day",
            subject: "Leadgenios lead",
            to: process.env.VUE_APP_EMAIL_TO.split(',').map((element:any) => element.trim()),
          };
          params = _.extend(params, answers);
          // let email = answers.email;
          // let name = answers.name;
          // let postalCode = answers.postalCode;
          // let budget = answers.budget.replace(/ /g, "%20");
          // let status = answers.status;
          // let payment = answers.payment;
          // let seats = answers.seats.toString();
          // let purpose = answers.purpose.toString();
          // if (sessionStorage.getItem("newEmail") != null) {
          //   window.dataLayer?.push({
          //     leadId: this.leadId,
          //     applicationId: this.applicationId,
          //     newEmail: sessionStorage.getItem("newEmail"),
          //     eventID: sessionStorage.getItem("eventID"),
          //   });
          //   FacebookServices.lead();
          //   this.triggerEvent();
          //   sessionStorage.removeItem("newEmail");
          // }

          if (sessionStorage.getItem("emailSent") != "1") {
            EmailServices.send(params).then(
              (res) => {
                sessionStorage.setItem("emailSent", "1");
                this.$router.push({
                  path: "resultados",
                  query: { SID: sessionStorage.getItem("SID") },
                });
              },
              (err) => {
                this.loading = false;
                this.$toast.add({
                  severity: "error",
                  summary: err,
                  life: this.toastTime,
                });
              }
            );
          } else {
            this.$router.push({
              path: "resultados",
              query: { SID: sessionStorage.getItem("SID") },
            });
          }
        } else {
          this.loading = this.submitted = false;
          window.scrollTo(0, 0);
          this.$router.push({
            path: "cuestionario",
            query: { step: this.currentStep, SID: this.currentStep == this.maxSteps ? sessionStorage.getItem("SID") : '' },
          });
        }
      }
    },
    goFirst() {
      sessionStorage.removeItem("leadId");
      sessionStorage.removeItem("applicationId");
      this.leadId = null;
      this.applicationId = null;
      this.to(this.minStep);
    },
    initParticles() {
      window.particlesJS("particles-js", {
        particles: {
          number: {
            value: 250,
            density: {
              enable: true,
              value_area: 700,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 10,
            },
          },
          opacity: {
            value: 0.75,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              opacity_min: 0.2,
              sync: true,
            },
          },
          size: {
            value: 3,
            random: true,
            anim: {
              enable: true,
              speed: 0,
              size_min: 50,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 0,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },
  },
});
